import React from 'react'
import { Link } from 'react-router-dom'
import { HiOutlineArrowLongLeft } from 'react-icons/hi2'
import '../styles/Privacy.css' // We can reuse the Privacy.css styles

function TermsOfService() {
  return (
    <div className="privacy-container">
      <nav>
        <Link to="/"><HiOutlineArrowLongLeft />Back to Home</Link>
      </nav>
      <div className="privacy-content">
        <h1>Terms of Service</h1>
        <p>Last updated: May 2024</p>

        <section className="info-page-section">
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing and using the Stoqup website ("Site"), you accept and agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our Site.</p>
        </section>

        <section className="info-page-section">
          <h2>2. Website Use</h2>
          <p>This website provides information about Stoqup's CRM services. You may:</p>
          <ul>
            <li>Browse our website content</li>
            <li>Contact us for more information</li>
            <li>Access public features of our platform</li>
          </ul>
        </section>

        <section className="info-page-section">
          <h2>3. Intellectual Property</h2>
          <p>All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Stoqup and is protected by intellectual property laws.</p>
        </section>

        <section className="info-page-section">
          <h2>4. Disclaimer</h2>
          <p>This website is provided "as is" without any warranties, expressed or implied. Stoqup does not warrant that the website will be error-free or uninterrupted.</p>
        </section>

        <section className="info-page-section">
          <h2>5. Links to Third-Party Sites</h2>
          <p>Our website may contain links to third-party websites. Stoqup is not responsible for the content or privacy practices of these external sites.</p>
        </section>

        <section className="info-page-section">
          <h2>6. Service Terms</h2>
          <p>For customers who subscribe to our CRM service, additional terms and conditions apply. These detailed service terms are provided during the subscription process and form part of our service agreement.</p>
        </section>

        <section className="info-page-section">
          <h2>7. Changes to Terms</h2>
          <p>We reserve the right to modify these terms at any time. Changes will be effective immediately upon posting to the website. Your continued use of the site constitutes acceptance of the modified terms.</p>
        </section>

        <section className="info-page-section">
          <h2>8. Contact</h2>
          <p>If you have any questions about these Terms of Service, please contact us at:</p>
          <p>Email: hello@stoqup.eu<br />
          Address: 9000 Gent, Belgium</p>
        </section>
      </div>
    </div>
  )
}

export default TermsOfService 